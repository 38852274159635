@import "variables";

@font-face {
  font-family: "MyLoveAngleFont";
  src: url("../fonts/LoveAngle/Angel-Love.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MyLonelinessFont";
  src: url("../fonts/Loneliness/Loneliness-font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MyLonelinessFontRegular";
  src: url("../fonts/Loneliness/Loneliness-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AdobeCaslonPro";
  src: url("../fonts/AdobeCaslonPro/Adobe-Caslon-Pro-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "AdobeCaslonProBold";
  src: url("../fonts/AdobeCaslonPro/Adobe-Caslon-Pro-Bold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "AdobeCaslonProItalic";
  src: url("../fonts/AdobeCaslonPro/Adobe-Caslon-Pro-Italic.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "georgia";
  src: url("../fonts/georgia/georgia.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "georgiaBold";
  src: url("../fonts/georgia/georgia-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "georgiaItalic";
  src: url("../fonts/georgia/georgia-italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "TimesNewRoman";
  src: url("../fonts/TimesNewRoman/times-new-roman.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TimesNewRomanBold";
  src: url("../fonts/TimesNewRoman/times-new-roman-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TimesNewRomanItalic";
  src: url("../fonts/TimesNewRoman/times-new-roman-italic.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;

  // font-family: AdobeCaslonProItalic, georgiaItalic, TimesNewRomanItalic, serif;
  // font-style: italic;
}

.our-container {
  max-width: 1776px;
  width: 100%;
  margin: 0 auto;
}

img {
  object-fit: cover;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-select:focus {
  box-shadow: none !important;
}

$customize-font: MyLonelinessFontRegular;

.bgColor {
  background-color: red !important;
}

.slick-dots {
  // display: none !important;
}

.not-product-found {
  max-width: 400px;
  width: 100%;
  font-weight: 500;
}

// ===========================*************************** Header Component CSS ***************************===========================
header {
  // height: 100px;
  border-bottom: 1px solid #e2e2e2;

  position: static;
  width: 100%;
  z-index: 1000;
  transition: top 0.3s ease-in-out, position 0.3s ease-in-out;

  .header-top {
    background-color: $primary-color;
  }

  .upper-menu-block {
    background-color: whitesmoke;
    padding: 10px 0px;

    .inner-container {
      .social-icons {
        display: flex;
        align-items: center;
        gap: 21px;
        .social-items {
          color: $secondary-color;
          cursor: pointer;

          &:hover {
            color: $hover-bg-color;
          }
        }
      }

      .logo-block {
        img {
          max-width: 130px;
          width: 100%;
        }
      }

      .mobile-icon-responsive {
        display: none;

        .mobile-personalized {
          display: none;
          .personalized {
            display: none;
            text-decoration: none;
            color: #ffffff !important;
            transition: all 0.3s ease-in;
            overflow: hidden;

            .animated-text {
              font-size: 18px;
              padding: 20px 35px;
              font-weight: bold;
              color: #000000 !important;
              background-color: #d4af37 !important;
              font-family: AdobeCaslonProItalic, georgiaItalic,
                TimesNewRomanItalic, serif;
              font-style: italic;
              animation: flip 1.4s ease-in-out infinite;

              span {
                margin-top: 6px;
              }
            }

            &.active {
              color: #ffffff !important;
            }

            @keyframes flip {
              0% {
                opacity: 0;
              }
              50% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
          }
        }

        .hamburgerMenu {
          display: none;
        }

        .mobile-cart-block {
          display: none;
          .mobile-shop-cart {
            display: none;
          }
        }
      }

      .sidebar-top-navbar {
        display: none;
        .sidebar-close-cross {
          display: none;
        }
      }

      .main-menu {
        background-color: whitesmoke;
        padding: 10px 0px;

        nav {
          .nav-links {
            gap: 30px;
            li {
              list-style: none;

              .nav-items {
                text-decoration: none;
                color: $secondary-color;
                transition: $transition-effect;

                &:hover {
                  color: $hover-bg-color;
                }

                &.active {
                  color: $primary-color !important;
                }
              }

              .shop-cart-label {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .c-value-mobile {
                  position: absolute;
                  top: -22px;
                  left: 6px;
                  background: #000000;
                  color: #ffffff;
                  font-size: 14px;
                  width: 22px;
                  height: 22px;
                  border-radius: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              .personalized {
                color: #ffffff !important;
                transition: all 0.3s ease-in;
                overflow: hidden;

                .animated-text {
                  font-size: 24px;
                  font-weight: bold;
                  color: #000000 !important;
                  background-color: #d4af37 !important;
                  font-family: AdobeCaslonProItalic, georgiaItalic,
                    TimesNewRomanItalic, serif;
                  font-style: italic;
                  animation: flip 1.4s ease-in-out infinite;

                  span {
                    margin-top: 13px;
                  }
                }

                &.active {
                  color: #000000 !important;
                }

                @keyframes flip {
                  0% {
                    opacity: 0;
                    // transform: scale(0);
                  }
                  50% {
                    opacity: 1;
                    // transform: scale(1);
                  }
                  100% {
                    opacity: 0;
                    // transform: scale(1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.fixed-header {
  position: fixed;
  top: 0;
  left: 0px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  transition: all 0.4s ease-in-out;
}
.nav-items-dropdown {
  position: relative;
  list-style: none;

  .nav-items {
    text-decoration: none;
    color: $secondary-color;
    transition: $transition-effect;
    cursor: pointer;

    &:hover {
      color: $hover-bg-color;
    }

    &.active {
      color: $primary-color !important;
    }
  }

  .header-dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 194%;
  }

  &:hover .header-dropdown-menu {
    display: block;
  }
}
.nav-items-dropdown .header-dropdown-menu {
  display: block; /* For mobile view on click */
}

// ===========================*************************** Footer Component CSS ***************************===========================
footer {
  height: 280px;
  background-color: whitesmoke;
  border-top: 1px solid #e2e2e2;

  .inner-container {
    max-width: 1400px;
    width: 100%;

    .footer-nav-links {
      flex: 1;
      border-bottom: 1px solid #e2e2e2;

      .fn-link {
        .fn-item {
          text-decoration: none;
          color: $secondary-color;
          transition: $transition-effect;

          &:hover {
            color: $hover-bg-color;
          }

          &.active {
            color: $primary-color !important;
          }
        }
      }
    }

    .main-footer {
      .footer-logo {
        img {
          max-width: 130px;
          width: 100%;
        }
      }

      .footer-links {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 40px;
        margin-bottom: 40px;

        p {
          font-size: 14px;
          color: #6a6161d6;
          border-right: 1px solid #6a6161d6;
          padding-right: 5px;
        }

        .term-condition {
          display: flex;
          align-items: center;
          justify-content: center;

          .tc-link {
            font-size: 14px;
            text-decoration: none;
            color: #6a6161d6;
            transition: $transition-effect;
            border-right: 1px solid #6a6161d6;
            padding: 0px 5px;

            &:hover {
              color: $hover-bg-color;
            }
          }

          .faq-link {
            font-size: 14px;
            text-decoration: none;
            color: #6a6161d6;
            transition: $transition-effect;
            border-left: 1px solid #6a6161d6;
            padding: 0px 5px;

            &:hover {
              color: $hover-bg-color;
            }
          }

          .tc-link-border {
            border: 0px;
          }
        }
      }

      .footer-social-links {
        .fs-link {
          text-decoration: none;
          color: $secondary-color;
          transition: $transition-effect;

          &:hover {
            color: $hover-bg-color;
          }

          .fs-link-item {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}

.app-body {
  min-height: calc(100vh - 380px);
}

// ===========================*************************** Pagination Component CSS ***************************===========================
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;

  button {
    border: 0;
    outline: none;
    background: #cdcdcd;
    padding: 10px;
  }
}

// ===========================*************************** Breadcrumb Component CSS ***************************===========================
.breadcrumb-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $primary-color;
  padding: 70px 0px;

  p {
    font-size: 40px;
    font-weight: 500;
    color: $white-color;
  }

  .breadcrumb {
    .breadcrumb-item {
      list-style: none;
      font-size: 14px;
      font-weight: 400;
      color: $secondary-color;

      .breadcrumb-item-link {
        text-decoration: none;
        color: #a3a3a3;

        &:hover {
          color: $white-color;
        }
      }
    }
  }
}

// ===========================*************************** Home Page CSS ***************************===========================
.home-page {
  .main-banner-block {
    .inner-container {
      .main-banner-img-block {
        img {
          display: block;
          max-width: 100%;
          width: 100%;
        }

        .main-banner-img-content {
          height: 100%;
          max-width: 100%;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          // background: rgba(0, 0, 0, 0.6);
          // height: 100%;
          // max-width: 800px;
          // width: 100%;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);

          h1 {
            font-size: 32px;
            color: $white-color;
          }

          p {
            font-size: 16px;
            color: $white-color;
            max-width: 800px;
            width: 100%;
          }

          .main-banner-link {
            text-decoration: none;
            padding: 15px 40px;
            border: 1px solid $hover-bg-color;
            color: $hover-bg-color;
            transition: $transition-effect;
            margin-bottom: 30px;

            &:hover {
              color: $white-color;
              background-color: $hover-bg-color;
              border: 1px solid $hover-bg-color;
            }
          }
        }
      }
    }
  }

  .shop-your-product {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;

        .shop-product-body {
          .spb-right-block {
            .spb-row {
              .spb-left-block {
                text-decoration: none;
                display: block;
                overflow: hidden;

                img {
                  display: block;
                  max-width: 100%;
                  transition: transform 0.3s ease;
                }

                .spb-left-img-content {
                  align-items: center;
                  padding: 0px 20px;
                  height: 100%;
                  width: 100%;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);

                  .h3 {
                    color: $white-color;
                    font-size: 30px;
                  }

                  p {
                    font-size: 16px;
                    color: $white-color;
                  }

                  .spb-left-row-link {
                    text-decoration: none;
                    padding: 15px 40px;
                    border: 1px solid $white-color;
                    color: $white-color;
                    transition: $transition-effect;

                    &:hover {
                      color: $white-color;
                      background-color: $hover-bg-color;
                      border: 1px solid $hover-bg-color;
                    }
                  }

                  &:hover {
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.2);
                    width: 100%;
                    height: 100%;
                  }
                }

                &:hover {
                  img {
                    transform: scale(1.1);
                  }
                }
              }

              .spb-right-block {
                text-decoration: none;
                display: block;
                overflow: hidden;

                img {
                  display: block;
                  max-width: 100%;
                  transition: transform 0.3s ease;
                }

                .spb-right-img-content {
                  align-items: center;
                  padding: 0px 20px;
                  height: 100%;
                  width: 100%;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);

                  .h3 {
                    color: $white-color;
                    font-size: 30px;
                  }

                  p {
                    font-size: 16px;
                    color: $white-color;
                  }

                  .spb-right-row-link {
                    text-decoration: none;
                    padding: 15px 40px;
                    border: 1px solid $white-color;
                    color: $white-color;
                    transition: $transition-effect;

                    &:hover {
                      color: $white-color;
                      background-color: $hover-bg-color;
                      border: 1px solid $hover-bg-color;
                    }
                  }

                  &:hover {
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.2);
                    width: 100%;
                    height: 100%;
                  }
                }

                &:hover {
                  img {
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .main-banner-video {
    .banner-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .sub-banner-block {
  //   .inner-container {
  //     .main-banner-img-block {
  //       position: relative;

  //       span {
  //         background-image: url("../../public/images/web-images/new-collection-banner.jpg");
  //         display: block;
  //         max-width: 100%;
  //         height: 700px;
  //         background-attachment: fixed;
  //         background-position: center;
  //         background-repeat: no-repeat;
  //         background-size: cover;
  //         position: relative;
  //       }

  //       .main-banner-img-content {
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -50%);
  //         height: 100%;
  //         max-width: 1200px;
  //         width: 100%;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         flex-direction: column;
  //         padding: 0px 100px;

  //         .h3 {
  //           font-size: 50px;
  //           font-weight: 700;
  //           text-align: center;
  //           text-transform: uppercase;
  //           margin-bottom: 15px;
  //           color: $white-color;
  //         }

  //         p {
  //           font-size: 16px;
  //           text-align: center;
  //           color: $white-color;

  //           h1 {
  //             display: inline-block;
  //             font-size: 16px;
  //             text-align: center;
  //             font-weight: 400;
  //             color: $white-color;
  //           }
  //         }

  //         .main-banner-link {
  //           text-decoration: none;
  //           border: 1px solid $white-color;
  //           color: $white-color;
  //           padding: 15px 40px;
  //           font-weight: 500;
  //           transition: $transition-effect;

  //           &:hover {
  //             color: $white-color;
  //             background-color: $hover-bg-color;
  //             border: 1px solid $hover-bg-color;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .customize-your-product {
    .inner-container {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      .image-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
        gap: 20px;

        .img-g-block {
          text-decoration: none;
          position: relative;
          overflow: hidden;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          img {
            width: 100%;
            height: auto;
            cursor: pointer;
            transition: $transition-effect;
          }

          img.default {
            display: block;
          }

          img.hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0s ease;
          }

          &:hover {
            img {
              transform: scale(1.1);
            }

            img.hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .client-logo {
    .inner-container {
      max-width: 1100px;
      width: 100%;
      margin: 0px auto;
      padding: 0px 48px;

      .client-logo-header {
        margin-bottom: 30px;

        .h3 {
          font-size: 30px;
          font-weight: 500;
          text-align: center;
          text-transform: capitalize;
          font-family: AdobeCaslonProItalic, georgiaItalic, TimesNewRomanItalic,
            serif;
          font-style: italic;
        }
      }
      .client-logo-body {
        .client-logo-row {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 50px;
          .client-logo-block {
            img {
              max-width: 120px;
              width: 100%;
              // filter: grayscale(100%);
              // transition: $transition-effect;

              // &:hover {
              //   filter: grayscale(0%);
              // }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** About Page CSS ***************************===========================
.about-page {
  .about-page-detail-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;

        .about-page-detail-body {
          .apd-block {
            .apd-row {
              justify-content: space-between;

              .apd-block-sub {
                overflow: hidden;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                &:hover {
                  .apd-img-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 0px 20px;
                    height: 100%;
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .h4 {
                      color: $white-color;
                      font-size: 16px;
                    }

                    .h6 {
                      color: $white-color;
                      font-size: 26px;
                    }

                    .apd-center-underline {
                      height: 3px;
                      max-width: 40px;
                      width: 100%;
                      background: $white-color;
                      margin: 20px 0px;
                    }

                    p {
                      font-size: 16px;
                      color: $white-color;
                      max-width: 255px;
                      width: 100%;
                    }

                    &:hover {
                      background: rgba(0, 0, 0, 0.8);
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                img {
                  display: block;
                  max-width: 100%;
                  transition: transform 0.3s ease;
                }

                .apd-img-content {
                  display: none;
                }

                &:hover {
                  img {
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .about-page-our-details {
    padding: 0px 0px 40px;

    .our-container {
      .inner-container {
        padding: 0px;

        .about-page-our-details-header {
          margin-bottom: 20px;
          padding: 0px 20px;

          h1 {
            font-size: 30px;
            font-weight: 500;
            text-align: center;
            text-transform: capitalize;
            font-family: AdobeCaslonProItalic, georgiaItalic,
              TimesNewRomanItalic, serif;
            font-style: italic;
          }
        }

        .about-page-our-details-body {
          .about-page-our-details-para {
            max-width: 1400px;
            width: 100%;
            margin: 0px auto;
            padding: 0px 20px;

            p {
              font-size: 16px;
              text-align: center;
              color: $para-color;
              margin-bottom: 10px;
            }
          }

          // .about-page-our-details-enquiry-block {
          //   .inner-container {
          //     padding: 60px 0px;

          //     .about-page-our-details-enquiry-img-block {
          //       position: relative;

          //       span {
          //         background-image: url("../../public/images/web-images/enquiry-banner.jpg");
          //         display: block;
          //         max-width: 100%;
          //         height: 700px;
          //         background-attachment: fixed;
          //         background-position: center;
          //         background-repeat: no-repeat;
          //         background-size: cover;
          //         position: relative;
          //       }

          //       .about-page-our-details-enquiry-img-content {
          //         position: absolute;
          //         top: 50%;
          //         left: 50%;
          //         transform: translate(-50%, -50%);
          //         height: 100%;
          //         max-width: 1000px;
          //         width: 100%;
          //         display: flex;
          //         align-items: center;
          //         justify-content: center;
          //         flex-direction: column;
          //         padding: 0px 100px;

          //         .h3 {
          //           font-size: 60px;
          //           font-weight: 700;
          //           text-align: center;
          //           text-transform: uppercase;
          //           margin-bottom: 15px;
          //           color: $white-color;
          //         }

          //         .h4 {
          //           font-size: 24px;
          //           font-weight: 700;
          //           text-align: center;
          //           text-transform: uppercase;
          //           margin-bottom: 15px;
          //           color: $white-color;
          //         }

          //         p {
          //           font-size: 16px;
          //           margin-bottom: 24px;
          //           text-align: center;
          //           color: $white-color;
          //         }

          //         .about-page-our-details-enquiry-link {
          //           text-decoration: none;
          //           border: 1px solid $white-color;
          //           color: $white-color;
          //           padding: 15px 30px;
          //           font-weight: 500;
          //           transition: $transition-effect;

          //           &:hover {
          //             color: $white-color;
          //             background-color: $hover-bg-color;
          //             border: 1px solid $hover-bg-color;
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
          .banner-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .about-page-our-details-my-services {
            padding: 0px 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .inner-container {
              max-width: 1400px;
              width: 100%;
              padding: 0px;

              .about-page-our-details-my-services-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 40px;

                .about-page-our-details-my-services-icon-block {
                  max-width: 400px;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  .services-icon {
                    font-size: 38px;
                    color: $primary-color;
                    margin-bottom: 10px;
                  }

                  .h3 {
                    font-size: 22px;
                    font-weight: 500;
                    text-transform: capitalize;
                    font-family: AdobeCaslonProItalic, georgiaItalic,
                      TimesNewRomanItalic, serif;
                    font-style: italic;
                  }

                  p {
                    font-size: 16px;
                    text-align: center;
                    color: $para-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** Contact Page CSS ***************************===========================
.contact-page {
  .contact-page-block {
    padding: 60px 0px;

    .our-container {
      .inner-container {
        padding: 0px 40px;
        max-width: 1400px;
        width: 100%;
        margin: 0px auto;

        .contact-page-header {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 60px 0px;

          h2 {
            font-size: 40px;
            font-weight: 500;
            text-align: center;
            text-transform: capitalize;
            margin-bottom: 10px;
          }

          p {
            font-size: 18px;
            text-align: center;
            color: $para-color;
          }
        }

        .contact-page-body {
          .contact-page-main-block {
            .contact-page-heading {
              margin-bottom: 40px;

              h1 {
                font-size: 30px;
                font-weight: 500;
                text-align: center;
                text-transform: capitalize;
                margin-bottom: 10px;
                font-family: AdobeCaslonProItalic, georgiaItalic,
                  TimesNewRomanItalic, serif;
                font-style: italic;
              }

              p {
                font-size: 16px;
                text-align: center;
                color: $para-color;
              }
            }

            .contact-page-main-row-block {
              display: flex;
              align-items: start;
              gap: 20px;

              .contact-sub-main-block {
                flex: 1;
                display: flex;
                align-items: start;
                gap: 20px;

                .contact-page-form {
                  flex: 1;
                  width: 100%;
                  // margin: 0px auto;

                  form {
                    padding-top: 15px;
                    padding-bottom: 80px;

                    .contact-form-group {
                      display: flex;
                      justify-content: center;
                      flex-direction: column;
                      margin-bottom: 30px;

                      label {
                        font-size: 14px;
                        margin-bottom: 4px;
                        color: $secondary-color;
                        text-transform: capitalize;
                        font-weight: 600;

                        span {
                          color: #ff0000;
                        }
                      }

                      input {
                        font-size: 14px;
                        font-weight: 500;
                        height: 45px;
                        padding: 0px 10px;
                        border-radius: 0px;
                        border: 1px solid $primary-color;
                        outline: none;
                        width: 100%;
                        font-weight: normal;

                        &::-webkit-inner-spin-button {
                          -webkit-appearance: none;
                        }

                        &::-webkit-outer-spin-button {
                          -webkit-appearance: none;
                        }
                      }

                      textarea {
                        font-size: 14px;
                        font-weight: 500;
                        padding: 10px;
                        border-radius: 0px;
                        border: 1px solid $primary-color;
                        outline: none;
                        width: 100%;
                        font-weight: normal;
                      }
                    }

                    .contact-form-button {
                      border: 1px solid $secondary-color;
                      outline: none;
                      width: 100%;
                      height: 45px;
                      font-size: 14px;
                      font-weight: 600;
                      text-transform: uppercase;
                      cursor: pointer;
                      color: $white-color;
                      background-color: $secondary-color;
                      transition: $transition-effect;

                      &:hover {
                        color: $white-color;
                        background-color: $hover-bg-color;
                        border-color: $hover-bg-color;
                      }
                    }
                  }
                }

                .contact-page-details {
                  flex: 1;
                  .contact-page-details-block {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 24px;

                    .contact-header-contact-us-block {
                      display: flex;
                      align-items: center;
                      height: 30px;
                      width: 60px;
                    }

                    .contact-page-details-block-link {
                      text-decoration: none;
                      color: $primary-color;

                      &:hover {
                        color: $hover-bg-color;
                        text-decoration: underline;
                      }
                    }

                    .contact-header-contact-us-block {
                    }
                  }
                }
              }

              .contact-page-iframe {
                flex: 0.8;
                iframe {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** personalized Page CSS ***************************===========================
.personalized-page {
  .personalized-page-block {
    .our-container {
      // ========================================================
      .banner-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      // ========================================================

      .inner-container {
        max-width: 1400px;
        width: 100%;

        .personalized-page-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            color: $para-color;
          }

          .btn-table-show-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            button {
              border: 0;
              outline: none;
              background: #cdcdcd;
              padding: 10px;
            }
          }
        }

        .personalized-page-body {
          display: flex;
          align-items: start;
          gap: 20px;
          // justify-content: space-between;

          .filter-section {
            max-width: 220px;
            width: 100%;
            padding: 5px;

            p {
              font-size: 24px;
              font-weight: 500;
            }

            .categories-checkbox {
              margin-bottom: 20px;
              display: flex;
              flex-direction: column;
              .form-check-input {
                &:focus {
                  border-color: #dee2e6;
                  outline: 0;
                  box-shadow: none;
                }
              }
            }
          }

          .product-page-row {
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

            .product-row-block {
              width: 100%;
              border: 1px solid #61616159;
              background-color: #ffffff;

              img {
                display: block;
                max-width: 100%;
                width: 100%;
              }

              .product-raw-block-details {
                .h5 {
                  font-size: 17px;
                  color: $primary-color;
                  font-family: AdobeCaslonProItalic, georgiaItalic,
                    TimesNewRomanItalic, serif;
                  font-style: italic;
                }

                p {
                  color: $para-color;
                }

                .product-raw-block-details-price {
                  color: $secondary-color;

                  .h4 {
                    color: $secondary-color;
                    text-decoration: line-through;
                  }
                }
              }
            }
          }

          .product-page-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            .pagination-disable-btn {
              padding: 10px 30px;
              outline: none;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border: 1px solid $white-color;
              background: $secondary-color;
              color: $white-color;
              border: 1px solid $secondary-color;

              &:hover {
                color: $secondary-color;
                background-color: $white-color;
                border: 1px solid $hover-bg-color;
              }
            }
            .prev-button-disabled {
              opacity: 0.3;
              color: $secondary-color;
              background-color: $white-color;
              border: 1px solid $secondary-color;
            }

            .pagination-next-btn {
              padding: 10px 30px;
              outline: none;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border: 1px solid $secondary-color;
              background-color: $secondary-color;
              color: $white-color;
              transition: $transition-effect;

              &:hover {
                color: $secondary-color;
                background-color: $white-color;
                border: 1px solid $hover-bg-color;
              }
            }
            .next-button-disabled {
              opacity: 0.3;
              color: $secondary-color;
              background-color: $white-color;
              border: 1px solid $secondary-color;
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** Product Page CSS ***************************===========================
.product-page {
  .product-page-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;

        .product-page-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            color: $para-color;
          }

          .btn-table-show-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            button {
              border: 0;
              outline: none;
              background: #cdcdcd;
              padding: 10px;
            }
          }
        }

        .product-page-body {
          display: flex;
          align-items: start;
          gap: 20px;
          flex-direction: column;

          .myFilteration-Btn {
            padding: 10px;
            border: 0px;
            outline: none;
            height: 45px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            max-width: 120px;
            width: 100%;
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: whitesmoke;
            color: $primary-color;
            transition: $transition-effect;

            &:hover {
              background-color: $hover-bg-color;
              color: $white-color;
            }

            .filterICON {
              font-size: 18px;
              margin-right: 4px;
            }
          }

          .filter-section {
            max-width: 220px;
            width: 100%;
            padding: 5px;

            p {
              font-size: 24px;
              font-weight: 500;
            }

            .categories-checkbox {
              margin-bottom: 20px;
              display: flex;
              flex-direction: column;
              .form-check-input {
                &:focus {
                  border-color: #dee2e6;
                  outline: 0;
                  box-shadow: none;
                }
              }
            }

            .filters-radio {
              margin-bottom: 20px;
              // p {
              //   display: flex;
              //   align-items: center;
              //   justify-content: space-between;

              //   .trash-product-filter {
              //     display: flex;
              //     align-items: center;
              //     cursor: pointer;
              //     font-size: 16px;
              //     text-decoration: underline;
              //   }
              // }

              .form-check-input {
                &:focus {
                  border-color: #dee2e6;
                  outline: 0;
                  box-shadow: none;
                }
              }
            }

            .filter-price-range {
              margin-bottom: 20px;
              .price-input-container {
                width: 100%;

                .price-input {
                  width: 100%;
                  font-size: 19px;
                  color: #555;
                  margin-bottom: 25px;

                  .price-field {
                    display: flex;
                    flex-direction: column;
                    height: 60px;
                    margin-bottom: 5px;

                    span {
                      font-size: 17px;
                    }

                    input {
                      flex: 1;
                      height: 35px;
                      font-size: 15px;
                      font-family: "DM Sans", sans-serif;
                      border-radius: 9px;
                      text-align: center;
                      border: 0px;
                      background: #e4e4e4;

                      &:focus-visible {
                        outline: none;
                      }
                    }
                  }
                }

                .slider-container {
                  width: 100%;
                  height: 6px;
                  position: relative;
                  background: #e4e4e4;
                  border-radius: 5px;

                  .price-slider {
                    height: 100%;
                    left: 25%;
                    right: 15%;
                    position: absolute;
                    border-radius: 5px;
                    background: #000000;
                  }
                }
              }

              .range-input {
                position: relative;

                input {
                  position: absolute;
                  width: 100%;
                  height: 5px;
                  background: none;
                  top: -5px;
                  pointer-events: none;
                  cursor: pointer;
                  -webkit-appearance: none;
                }
              }
            }
            /* Styles for the range thumb in WebKit browsers */
            input[type="range"]::-webkit-slider-thumb {
              height: 18px;
              width: 18px;
              border-radius: 70%;
              background: #555;
              pointer-events: auto;
              -webkit-appearance: none;
            }

            .size-checkbox {
              margin-bottom: 20px;
              display: flex;
              flex-direction: column;
              .form-check-input {
                &:focus {
                  border-color: #dee2e6;
                  outline: 0;
                  box-shadow: none;
                }
              }
            }

            .material-drop-down {
              select {
                border-color: #e4e4e4;
                background-color: #e4e4e4;
              }
            }
          }

          .product-page-row {
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

            .product-row-block {
              width: 100%;
              border: 1px solid #61616159;
              background-color: #ffffff;

              img {
                display: block;
                max-width: 100%;
                width: 100%;
              }

              .product-raw-block-details {
                .h5 {
                  font-size: 17px;
                  color: $primary-color;
                  font-family: AdobeCaslonProItalic, georgiaItalic,
                    TimesNewRomanItalic, serif;
                  font-style: italic;
                }

                p {
                  color: $para-color;
                }

                .product-raw-block-details-price {
                  color: $secondary-color;

                  .h4 {
                    color: $secondary-color;
                    text-decoration: line-through;
                  }
                }
              }
            }
          }

          .product-page-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            .pagination-disable-btn {
              padding: 10px 30px;
              outline: none;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border: 1px solid $white-color;
              background: $secondary-color;
              color: $white-color;
              border: 1px solid $secondary-color;

              &:hover {
                color: $secondary-color;
                background-color: $white-color;
                border: 1px solid $hover-bg-color;
              }
            }
            .prev-button-disabled {
              opacity: 0.3;
              color: $secondary-color;
              background-color: $white-color;
              border: 1px solid $secondary-color;
            }

            .pagination-next-btn {
              padding: 10px 30px;
              outline: none;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border: 1px solid $secondary-color;
              background-color: $secondary-color;
              color: $white-color;
              transition: $transition-effect;

              &:hover {
                color: $secondary-color;
                background-color: $white-color;
                border: 1px solid $hover-bg-color;
              }
            }
            .next-button-disabled {
              opacity: 0.3;
              color: $secondary-color;
              background-color: $white-color;
              border: 1px solid $secondary-color;
            }
          }
        }
      }
    }
  }

  .offcanvas {
    height: 100%;
    .offcanvas-header {
      .btn-close:focus {
        outline: 0;
        box-shadow: none !important;
      }
    }
    .offcanvas-body {
      .filter-section {
        max-width: 100%;
        width: 100%;
        padding: 5px;

        p {
          font-size: 24px;
          font-weight: 500;
        }

        .categories-checkbox {
          margin-bottom: 25px;
          display: flex;
          flex-direction: column;
          .form-check-input {
            &:focus {
              border-color: #dee2e6;
              outline: 0;
              box-shadow: none;
            }
          }
        }

        .filters-radio {
          margin-bottom: 25px;
          // p {
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;

          //   .trash-product-filter {
          //     display: flex;
          //     align-items: center;
          //     cursor: pointer;
          //     font-size: 16px;
          //     text-decoration: underline;
          //   }
          // }

          .form-check-input {
            &:focus {
              border-color: #dee2e6;
              outline: 0;
              box-shadow: none;
            }
          }
        }

        .filter-price-range {
          margin-bottom: 25px;
          .price-input-container {
            width: 100%;

            .price-input {
              width: 100%;
              font-size: 19px;
              color: #555;
              margin-bottom: 25px;

              .price-field {
                display: flex;
                flex-direction: column;
                height: 60px;
                margin-bottom: 5px;

                span {
                  font-size: 17px;
                }

                input {
                  flex: 1;
                  height: 35px;
                  font-size: 15px;
                  font-family: "DM Sans", sans-serif;
                  border-radius: 9px;
                  text-align: center;
                  border: 0px;
                  background: #e4e4e4;

                  &:focus-visible {
                    outline: none;
                  }
                }
              }
            }

            .slider-container {
              width: 100%;
              height: 6px;
              position: relative;
              background: #e4e4e4;
              border-radius: 5px;

              .price-slider {
                height: 100%;
                // left: 25%;
                // right: 15%;
                position: absolute;
                border-radius: 5px;
                background: #000000;
              }
            }
          }

          .range-input {
            position: relative;

            input {
              position: absolute;
              width: 100%;
              height: 5px;
              background: none;
              top: -5px;
              pointer-events: none;
              cursor: pointer;
              -webkit-appearance: none;
            }
          }
        }
        /* Styles for the range thumb in WebKit browsers */
        input[type="range"]::-webkit-slider-thumb {
          height: 18px;
          width: 18px;
          border-radius: 70%;
          background: #555;
          pointer-events: auto;
          -webkit-appearance: none;
        }

        .size-checkbox {
          margin-bottom: 25px;
          display: flex;
          flex-direction: column;
          .form-check-input {
            &:focus {
              border-color: #dee2e6;
              outline: 0;
              box-shadow: none;
            }
          }
        }

        .material-drop-down {
          margin-bottom: 25px;
          // select {
          //   border-color: #e4e4e4;
          //   background-color: #e4e4e4;
          // }
          .form-check-input:focus {
            border: 0;
            outline: 0;
            box-shadow: none !important;
          }
        }

        // .clear-filter {
        //   width: 100%;

        //   .trash-product-filter {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     cursor: pointer;
        //     font-size: 16px;
        //     height: 35px;
        //     font-size: 15px;
        //     font-family: "DM Sans", sans-serif;
        //     border-radius: 9px;
        //     text-align: center;
        //     border: 0px;
        //     background: #e4e4e4;
        //     font-weight: 600;
        //   }
        // }
      }
    }

    .offcanvas-footer {
      .clear-filter {
        width: 100%;

        .trash-product-filter {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 16px;
          height: 45px;
          font-size: 15px;
          font-family: "DM Sans", sans-serif;
          text-align: center;
          border: 0px;
          background: #e4e4e4;
          font-weight: 600;
        }
      }
    }
  }
}

// ===========================*************************** Product Details Page CSS ***************************===========================
.product-single-page {
  .product-single-page-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;

        .back-btn-per-page {
          border: 0px;
          outline: 0px;
          background: #ffffff;
          color: #000000;
          font-size: 18px;
          font-weight: 500;
          text-decoration: underline;
          display: flex;
          align-items: center;
        }

        .product-single-page-block-row {
          .product-single-page-img-grp {
            .product-single-page-img-group {
              img {
                display: block;
                cursor: pointer;
                width: 100%;
                max-width: 115px;
                padding: 7px;
                border: 1px solid $border-color;
              }
            }
          }

          .product-single-page-main-img {
            border: 1px solid $border-color;
            overflow: hidden;
            padding: 30px;

            img {
              display: block;
              max-width: 100%;
              width: 100%;
              transition: transform 0.5s ease;

              &.zoomed {
                transform: scale(3);
              }
            }

            .product-single-page-main-image-current-details {
              position: absolute;
              bottom: 45px;
              right: 80px;

              span {
                color: #000000;
                font-family: $customize-font;
                font-size: 70px;
                text-transform: capitalize;
              }
            }

            .zodiacs-flower-upper-image {
              position: absolute;
              // top: 120px;
              left: 80px;
              bottom: 93px;
              width: 160px;
            }

            .share-button-product {
              top: 2px;
              right: 10px;

              .share-button-icon {
                cursor: pointer;
              }

              .share-button-options {
                .product-share-icon-all {
                  cursor: pointer;
                  &:hover {
                    color: $hover-bg-color;
                  }
                }
              }
            }
          }

          .product-single-page-details {
            h1 {
              font-family: AdobeCaslonProItalic, georgiaItalic,
                TimesNewRomanItalic, serif;
              font-style: italic;
            }
            .product-price {
              .price-icon {
                font-size: 14px;
                margin-top: 2px;
              }

              h4 {
                text-decoration: line-through;
              }
            }
            .product-single-page-details-fields {
              .product-single-page-form-group {
                label {
                  color: $secondary-color;
                }

                input {
                  font-size: 14px;
                  font-weight: 500;
                  height: 45px;
                  padding: 0px 10px;
                  border-radius: 0px;
                  border: 1px solid $primary-color;
                  outline: none;
                  width: 100%;
                }

                select {
                  font-size: 14px;
                  font-weight: 500;
                  height: 45px;
                  border-radius: 0px;
                  border: 1px solid $primary-color;
                  outline: none;
                  width: 100%;
                }

                .product-single-page-colors-group {
                  .radio-btn-color {
                    border: 0px;
                    outline: none;
                    height: 30px;
                    max-width: 30px;
                    width: 100%;
                    border-radius: 100%;
                    cursor: pointer;

                    &.active {
                      border: 1px solid $secondary-color;
                    }
                  }
                }

                .product-single-page-quantity {
                  .quantity-block {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 14px;
                    max-width: 145px;
                    width: 100%;
                    padding: 0px;
                    border: 1px solid #e2e2e2;

                    .quantity-btn {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding: 12px 15px;
                      font-size: 14px;
                      border: 0px;
                      background: whitesmoke;
                    }
                  }
                }
              }

              .product-single-page-customize-button {
                border: 0px;
                outline: none;
                width: 100%;
                max-width: fit-content;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                color: $secondary-color;
                text-align: right;
                text-decoration: underline;
                transition: $transition-effect;

                &:hover {
                  color: $hover-bg-color;
                }

                &.active {
                  color: $primary-color;
                }
              }

              .product-single-page-form-button {
                border: 0px;
                outline: none;
                width: 100%;
                height: 45px;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                background-color: $primary-color;
                color: $white-color;
                transition: $transition-effect;

                &:hover {
                  color: $white-color;
                  background-color: $hover-bg-color;
                }
              }
            }
            .productDescAccordion {
              .accordion {
                border-top: 1px solid #dee2e6;
                border-bottom: 1px solid #dee2e6;
                .accordion-item {
                  .accordion-header {
                    .accordion-button {
                      font-weight: 500;
                      &:focus {
                        box-shadow: none;
                      }
                    }
                  }
                  .accordion-body {
                    ul {
                      li {
                        font-weight: 400;
                      }
                    }
                  }
                }
                .accordion-button:not(.collapsed) {
                  background-color: $white-color;
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .product-suggestion-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;
      }
    }
  }
}

// ===========================*************************** Product Page CSS ***************************===========================
.b2b-page {
  .b2b-page-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;

        .b2b-page-body {
          display: flex;
          align-items: start;
          gap: 20px;
          // justify-content: space-between;

          .filter-section {
            max-width: 200px;
            width: 100%;
            padding: 5px;

            p {
              font-size: 24px;
              font-weight: 500;
            }

            .filter-checkboxes {
              display: flex;
              // align-items: center;
              flex-direction: column;
              // gap: 60px;
              .form-check-input {
                &:focus {
                  border-color: #dee2e6;
                  outline: 0;
                  box-shadow: none;
                }
              }
            }
          }

          .b2b-page-row {
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

            .b2b-modal-btn {
              border: 0px;
              padding: 0px;
              margin: 0px;
              height: min-content;
              background-color: $white-color;
              cursor: pointer;

              .b2b-row-block {
                width: 100%;
                border: 1px solid #61616159;

                img {
                  display: block;
                  max-width: 100%;
                  width: 100%;
                }

                .b2b-raw-block-details {
                  .h5 {
                    font-size: 16px;
                    text-align: left;
                    color: $primary-color;
                    font-family: AdobeCaslonProItalic, georgiaItalic,
                      TimesNewRomanItalic, serif;
                    font-style: italic;
                  }

                  p {
                    color: $para-color;
                  }

                  .b2b-raw-block-details-price {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    color: $secondary-color;

                    .h5 {
                      color: $secondary-color;
                    }
                  }
                }
              }
            }
          }

          .b2b-page-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            .pagination-disable-btn {
              padding: 10px 30px;
              outline: none;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border: 1px solid $white-color;
              background: $secondary-color;
              color: $white-color;
              border: 1px solid $secondary-color;

              &:hover {
                color: $secondary-color;
                background-color: $white-color;
                border: 1px solid $hover-bg-color;
              }
            }
            .prev-button-disabled {
              opacity: 0.3;
              color: $secondary-color;
              background-color: $white-color;
              border: 1px solid $secondary-color;
            }

            .pagination-next-btn {
              padding: 10px 30px;
              outline: none;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border: 1px solid $secondary-color;
              background-color: $secondary-color;
              color: $white-color;
              transition: $transition-effect;

              &:hover {
                color: $secondary-color;
                background-color: $white-color;
                border: 1px solid $hover-bg-color;
              }
            }
            .next-button-disabled {
              opacity: 0.3;
              color: $secondary-color;
              background-color: $white-color;
              border: 1px solid $secondary-color;
            }
          }
        }
      }
    }
  }
}
.b2b-page-user-details-modal-popup {
  .modal-header {
    border-bottom: none;
    .btn-close {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .modal-body {
    form {
      padding-top: 15px;
      padding-bottom: 80px;

      .b2b-form-group {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 30px;

        label {
          font-size: 14px;
          margin-bottom: 4px;
          color: $secondary-color;
          text-transform: capitalize;
          font-weight: 600;

          span {
            color: #ff0000;
          }
        }

        input {
          font-size: 14px;
          font-weight: 500;
          height: 45px;
          padding: 0px 10px;
          border-radius: 0px;
          border: 1px solid $primary-color;
          outline: none;
          width: 100%;
          font-weight: normal;

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }

        textarea {
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          border-radius: 0px;
          border: 1px solid $primary-color;
          outline: none;
          width: 100%;
          font-weight: normal;
        }
      }

      .b2b-form-button {
        border: 1px solid $secondary-color;
        outline: none;
        width: 100%;
        height: 45px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        color: $white-color;
        background-color: $secondary-color;
        transition: $transition-effect;

        &:hover {
          color: $white-color;
          background-color: $hover-bg-color;
          border-color: $hover-bg-color;
        }
      }
    }
  }
}

// ===========================*************************** Cart Page CSS ***************************===========================
.cart-page {
  .our-container {
    .inner-container {
      padding: 0px 40px;
      max-width: 1000px;
      width: 100%;
      margin: 0px auto;

      .cart-page-block {
        .cart-page-heading {
          margin-bottom: 50px;

          h1 {
            font-size: 30px;
            font-weight: 500;
            text-align: center;
            text-transform: capitalize;
            margin-bottom: 10px;
          }
        }

        .cart-page-body {
          .cart-alert-box {
            font-size: 16px;
            color: $secondary-color;

            .cart-alert-box-link {
              font-weight: normal;
              color: $primary-color;
            }
          }
          ul {
            li {
              .cart-detail-list-item {
                display: flex;
                align-items: start;
                justify-content: center;

                .left-side-cart-img {
                  .cart-detail-list-item-link {
                    text-decoration: none;

                    img {
                      display: block;
                      max-width: 176px;
                      width: 100%;
                    }
                  }
                }

                .right-side-cart-content {
                  .right-cart-heading {
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    .cart-detail-list-item-link {
                      text-decoration: none;
                      color: $secondary-color;

                      .h3 {
                        font-size: 19px;
                        font-family: AdobeCaslonProItalic, georgiaItalic,
                          TimesNewRomanItalic, serif;
                        font-style: italic;
                      }
                    }

                    .cart-rupees-sign {
                      font-size: 10px;
                      margin-bottom: 9px;
                    }
                  }

                  p {
                    font-size: 16px;
                  }

                  .right-cart-footer {
                    display: flex;
                    align-items: center;
                    justify-content: start;

                    .cart-page-quantity {
                      .quantity-block {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 0px;
                        max-width: 118px;
                        width: 100%;
                        padding: 0px;
                        border: 1px solid #e2e2e2;

                        .quantity-btn {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 8px;
                          font-size: 12px;
                          border: 0px;
                          background: whitesmoke;
                        }
                      }
                    }
                    .cart-trash-btn {
                      padding-left: 10px;
                      padding-right: 10px;
                      margin-left: 10px;
                      border-left: 1px solid $border-color;
                      border-right: 1px solid $border-color;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .faTrash-btn {
                        font-size: 14px;
                        border: 0px;
                        outline: 0px;
                        background: $white-color;
                        color: $primary-color;
                        font-weight: 500;

                        &:hover {
                          text-decoration: underline;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .cart-sub-total {
            align-items: center;
            justify-content: space-between;

            .h4 {
              font-size: 20px;
              font-weight: 500;

              .subtotal-rupees-sign {
                font-size: 10px;
              }
            }

            .cart-footer-btn-group {
              display: flex;
              align-items: center;
              justify-content: end;
              gap: 10px;
              max-width: 325px;
              width: 100%;

              .cart-sub-total-btn {
                border: 0px;
                outline: none;
                max-width: 200px;
                width: 100%;
                height: 45px;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                padding: 10px 20px;
                text-decoration: none;
                text-align: center;
                line-height: 25px;
                background-color: $secondary-color;
                color: $white-color;
                transition: $transition-effect;

                &:hover {
                  background-color: $hover-bg-color;
                }
              }
            }
          }

          .cart-continue-shopping-link {
            text-decoration: none;
            color: $primary-color;
            display: flex;
            justify-content: end;
            align-items: center;

            .left-arrow-cart-cs {
              font-size: 21px;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .cart-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .btn-close {
            &:focus {
              box-shadow: none;
            }
          }
        }

        .modal-body {
          .form-control,
          .form-select {
            font-size: 14px;
          }

          .form-fixed-height {
            height: 45px;
          }

          .form-control:focus,
          .form-select:focus,
          .accordion-button:focus {
            border-color: #dee2e6;
            outline: 0;
            box-shadow: none;
          }
        }

        .modal-footer {
          .cart-modal-cancel-btn {
            border: 0px;
            outline: none;
            max-width: 100px;
            width: 100%;
            height: 45px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 11px;
            padding: 10px 20px;
            text-decoration: none;
            text-align: center;
            line-height: 25px;
            background-color: $para-color;
            color: $white-color;
            transition: $transition-effect;

            &:hover {
              background-color: $hover-bg-color;
            }
          }

          .cart-modal-btn {
            border: 0px;
            outline: none;
            max-width: 150px;
            width: 100%;
            height: 45px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 11px;
            padding: 10px 20px;
            text-decoration: none;
            text-align: center;
            line-height: 25px;
            background-color: $secondary-color;
            color: $white-color;
            transition: $transition-effect;

            &:hover {
              background-color: $hover-bg-color;
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** CheckOut Page CSS ***************************===========================
.checkout-page {
  .checkout-page-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;
        padding: 0px 50px;

        .checkout-page-row-block {
          display: flex;
          align-items: start;
          justify-content: space-between;
          gap: 50px;

          .checkout-page-row-left-block {
            .checkout-page-body {
              form {
                .form-control,
                .form-select {
                  font-size: 14px;
                }

                .form-fixed-height {
                  height: 45px;
                }

                .form-control:focus,
                .form-check-input:focus,
                .form-select:focus,
                .accordion-button:focus {
                  border-color: #dee2e6;
                  outline: 0;
                  box-shadow: none;
                }

                .form-check-input {
                  margin-top: 6px;
                  font-size: 14px;
                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                .pay-now-btn {
                  padding: 10px 30px;
                  border: 0px;
                  outline: none;
                  height: 45px;
                  font-size: 14px;
                  font-weight: 600;
                  text-transform: uppercase;
                  cursor: pointer;
                  width: 100%;
                  background-color: $secondary-color;
                  color: $white-color;
                  transition: $transition-effect;

                  &:hover {
                    background-color: $hover-bg-color;
                    color: $white-color;
                  }
                }
              }
            }
          }
          .checkout-page-row-right-block {
            .subtotal-checkout-block {
              border: 1px solid $border-color;
              padding: 14px;
              .subtotal-block {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .h5 {
                  font-size: 14px;
                  color: $para-color;
                }

                .h3 {
                  font-size: 18px;
                  font-weight: 500;

                  .checkout-rupees-sign {
                    font-size: 10px;
                    margin-bottom: 9px;
                  }
                }
              }
              .shipping-block {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .h5 {
                  font-size: 14px;
                  color: $para-color;
                }

                .h3 {
                  font-size: 18px;
                  font-weight: 500;
                }
              }
              .total-amount-block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .h5 {
                  font-size: 14px;
                  color: $para-color;
                }

                .h3 {
                  font-size: 18px;
                  font-weight: 500;

                  .checkout-rupees-sign {
                    font-size: 10px;
                    margin-bottom: 9px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** Privacy Policy Page CSS ***************************===========================
.privacy-policy-page {
  .our-container {
    .inner-container {
      max-width: 1400px;
      width: 100%;
      padding: 0px 50px;

      .privacy-policy-page-body {
        .privacy-policy-greeting {
          .privacy-policy-greeting-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .privacy-policy-information {
          .privacy-policy-information-body {
            p {
              font-size: 16px;
              color: $para-color;
            }

            ul {
              li {
                color: $para-color;
              }
            }
          }
        }

        .privacy-policy-security {
          .privacy-policy-security-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .privacy-policy-cookies {
          .privacy-policy-cookies-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .privacy-policy-disclaimer {
          .privacy-policy-disclaimer-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .privacy-policy-contact-info {
          .privacy-policy-contact-info-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** Terms And Condition Page CSS ***************************===========================
.tnc-page {
  .our-container {
    .inner-container {
      max-width: 1400px;
      width: 100%;
      padding: 0px 50px;

      .tnc-page-body {
        .tnc-greeting {
          .tnc-greeting-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .tnc-cookies {
          .tnc-cookies-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .tnc-license {
          .tnc-license-body {
            p {
              font-size: 16px;
              color: $para-color;
            }

            ul {
              li {
                color: $para-color;
              }
            }
          }
        }

        .tnc-iFrames {
          .tnc-iFrames-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .tnc-content-liability {
          .tnc-content-liability-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .tnc-reservation-right {
          .tnc-reservation-right-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .tnc-links-web {
          .tnc-links-web-body {
            p {
              font-size: 16px;
              color: $para-color;
            }
          }
        }

        .tnc-disclaimer {
          .tnc-disclaimer-body {
            p {
              font-size: 16px;
              color: $para-color;
            }

            ul {
              li {
                color: $para-color;
              }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** Faq Page CSS ***************************===========================
.faq-page {
  .our-container {
    .inner-container {
      max-width: 1000px;
      width: 100%;

      .faq-page-faq {
        padding: 60px 0px;
        .faq-page-faq-ul {
          border-top: 1px solid #dee2e6;
          border-bottom: 1px solid #dee2e6;
          .accordion-item {
            .accordion-header {
              .accordion-button {
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
          .accordion-button:not(.collapsed) {
            background-color: $white-color;
            box-shadow: none;
          }
        }
      }
    }
  }
}

// ===========================*************************** NotFound Page CSS ***************************===========================
.not-found-page {
  .not-found-page-block {
    padding: 60px 0px;

    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;
        margin: 0px auto;
        padding: 0px 40px;

        .content-page-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 100px;

          .not-found-img-block {
            flex: 1;

            img {
              display: block;
              max-width: 100%;
            }
          }

          .not-found-content-block {
            flex: 1;

            h1 {
              font-size: 150px;
              font-weight: 700;
              color: $secondary-color;
              margin-bottom: 14px;
            }

            .h3 {
              font-size: 35px;
              font-weight: 500;
            }

            p {
              font-size: 16px;
              color: $para-color;
              margin-bottom: 0px;
            }

            .not-found-content-link {
              color: $secondary-color;
              font-size: 14px;
              transition: $transition-effect;

              &:hover {
                color: $hover-bg-color;
              }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** Loading CSS ***************************===========================
.spinner-examples {
  padding: 4rem;

  .example {
    display: block;
    position: relative;
    letter-spacing: 0.5px;
    margin-bottom: 30px;

    .smooth {
      &::after {
        border-top: 4px solid $secondary-color;
        border-left: 4px solid $secondary-color;
        border-right: 4px solid rgba(255, 255, 255, 0);
        animation: spinner 0.6s linear infinite;
      }
    }

    .spinner {
      &::after {
        content: "";
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        border-radius: 50%;
      }
    }
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

// ===========================*************************** gallery Page CSS ***************************===========================
.gallery-page {
  .gallery-image-page-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;

        .gallery-image-page-body {
          display: flex;
          align-items: start;
          gap: 20px;
          position: relative;

          .gallery-image-filter-section {
            max-width: 220px;
            width: 100%;
            padding: 13px;
            border: 1px solid;
            position: sticky;
            top: 142px;
            left: 0;

            button {
              display: block;
              border: 0;
              outline: 0;
              padding: 7px 30px;
              font-size: 16px;
              font-weight: 500;
              max-width: 210px;
              width: 100%;
              margin-bottom: 8px;
            }
            .active {
              background-color: $primary-color;
              color: $white-color;
            }
          }

          .gallery-image-page-row {
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

            .gallery-image-row-block {
              width: 100%;
              border: 1px solid #61616159;
              background-color: #ffffff;

              img {
                display: block;
                max-width: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .gallery-video-page-block {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;

        .gallery-video-page-body {
          display: flex;
          align-items: start;
          gap: 20px;

          .gallery-video-filter-section {
            max-width: 220px;
            width: 100%;
            padding: 5px;

            button {
              display: block;
              border: 0;
              outline: 0;
              padding: 7px 30px;
              font-size: 16px;
              font-weight: 500;
              max-width: 210px;
              width: 100%;
              margin-bottom: 8px;
            }
          }

          .gallery-video-page-row {
            grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));

            .gallery-video-row-block {
              width: 100%;
              border: 1px solid #61616159;
              background-color: #ffffff;

              .video-container {
              }
              // video {
              //   display: block;
              //   max-width: 100%;
              //   width: 100%;
              // }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** blog Page CSS ***************************===========================
.blog-section {
  .blog-body-section {
    .our-container {
      .inner-container {
        max-width: 1400px;
        width: 100%;
        .blog-section-body-row {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

          .blog-section-block {
            border: 1px solid #d3d3d3;
            padding: 10px;
            border-radius: 14px;

            .blog-section-block-img {
              margin-bottom: 20px;
              display: block;
              text-decoration: none;

              img {
                max-width: 800px;
                width: 100%;
                border-radius: 14px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              }
            }

            .blog-section-block-content {
              .h5 {
                display: block;
                text-decoration: none;
                text-align: left;
                font-family: AdobeCaslonProItalic, georgiaItalic,
                  TimesNewRomanItalic, serif;
                font-style: italic;
                color: #0f1d55;
                margin-bottom: 5px;
              }

              p {
                text-align: left;
                // font-family: "Montserrat-Regular";
                color: #1c1c1c;
                font-size: 1rem;
                margin-bottom: 5px;
                color: #717172;
                margin-bottom: 10px;
              }
            }

            .blog-section-block-learn-more-link {
              text-decoration: none;
              font-size: 1rem;
              color: #1c1c1c;
              // font-family: "Montserrat-Regular";
              font-weight: 600;

              &:hover {
                color: #0f1d55;
                text-decoration: underline;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** blog detail Page CSS ***************************===========================
.blog-detail-section {
  .blog-detail-body-section {
    .our-container {
      .inner-container {
        max-width: 800px;
        width: 100%;
        margin: 0px auto;
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 10px;

        .back-btn-per-page {
          border: 0px;
          outline: 0px;
          background: #ffffff;
          color: #000000;
          font-size: 18px;
          font-weight: 500;
          text-decoration: underline;
          display: flex;
          align-items: center;
        }

        .blog-detail-section-block {
          .blog-detail-section-block-img {
            margin-bottom: 30px;

            img {
              max-width: 800px;
              width: 100%;
            }
          }

          .blog-detail-section-block-content {
            h1 {
              font-size: 1.8rem;
              text-align: left;
              text-transform: uppercase;
              font-family: AdobeCaslonProItalic, georgiaItalic,
                TimesNewRomanItalic, serif;
              font-style: italic;
              margin-bottom: 5px;
            }

            p {
              text-align: left;
              // font-family: "Montserrat-Regular";
              font-size: 1rem;
              margin-bottom: 15px;
              color: #717172;
              margin-bottom: 5px;
            }

            .main-title-for-blog {
              font-family: AdobeCaslonProItalic, georgiaItalic,
                TimesNewRomanItalic, serif;
              font-style: italic;
              margin-bottom: 5px;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

// ===========================*************************** pagination CSS ***************************===========================
.paginationBlog {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;

  button {
    border: none;
    background: none;
    margin: 0 5px;
    cursor: pointer;
    padding: 5px 10px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  span {
    margin: 0 10px;
  }
}

// ::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #e0e0e0;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
