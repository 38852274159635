@import "variables";
@import "./app.scss";

@media screen and (min-device-width: 1000px) and (max-device-width: 1460px) {
  .home-page {
    .main-banner-block {
      .inner-container {
        .main-banner-img-block {
          .main-banner-img-content {
            .main-banner-link {
              text-decoration: none;
              padding: 15px 40px;
              border: 1px solid $hover-bg-color;
              color: $hover-bg-color;
              transition: $transition-effect;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .customize-your-product {
      .inner-container {
        .image-grid {
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }
      }
    }
  }

  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          .product-single-page-block-row {
            .product-single-page-img-grp {
              .product-single-page-img-group {
                img {
                  display: block;
                  cursor: pointer;
                  width: 100%;
                  max-width: 115px;
                  padding: 10px;
                }
              }
            }

            .product-single-page-main-img {
              overflow: hidden;
              padding: 30px;

              img {
                display: block;
                max-width: 100%;
              }

              .product-single-page-main-image-current-details {
                position: absolute;
                // bottom: 38px;
                right: 75px;

                span {
                  font-size: 75px;
                  text-transform: capitalize;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 147px;
                left: 87px;
                width: 150px;
              }

              .share-button-product {
                top: 2px;
                right: 10px;

                .share-button-icon {
                  cursor: pointer;
                }

                .share-button-options {
                  .product-share-icon-all {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1230px) {
  .personalized-page {
    .personalized-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .personalized-page-body {
            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(214px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-page {
    .product-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-page-body {
            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(214px, 1fr));
            }
          }
        }
      }
    }
  }

  .b2b-page {
    .b2b-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .b2b-page-body {
            .b2b-page-row {
              grid-template-columns: repeat(auto-fill, minmax(214px, 1fr));
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-page-row {
              grid-template-columns: repeat(auto-fill, minmax(214px, 1fr));
            }
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1156px) {
  .home-page {
    .customize-your-product {
      .inner-container {
        .image-grid {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }
      }
    }
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 1024px) {
  header {
    border-bottom: 1px solid #e2e2e2;

    .upper-menu-block {
      .inner-container {
        .country-block {
          display: none;
        }

        .mobile-icon-responsive {
          display: flex;
          align-items: center;
          gap: 10px;

          .mobile-personalized {
            display: block;
            .personalized {
              display: block;
              // .animated-text{
              //   font-size: 14px;
              //   padding: 7px 20px;
              // }
              .animated-text {
                font-size: 14px;
                padding: 7px 20px;
                font-weight: bold;
                color: #000000 !important;
                background-color: #d4af37 !important;
                font-family: AdobeCaslonProItalic, georgiaItalic,
                  TimesNewRomanItalic, serif;
                font-style: italic;
                animation: flip 1.4s ease-in-out infinite;

                span {
                  margin-top: 6px;
                }
              }
            }
          }

          .hamburgerMenu {
            display: block;
          }

          .mobile-cart-block {
            display: block;
            color: $secondary-color;

            .mobile-shop-cart {
              display: block;
              color: $primary-color;
            }
          }

          .c-value-mobile {
            position: absolute;
            top: -24px;
            right: -4px;
            font-size: 12px;
            color: $white-color;
            background-color: $primary-color;
            padding: 5px;
            height: 22px;
            width: 22px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .main-menu {
          display: none;
          padding: 10px 0px;

          .sidebar-top-navbar {
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-block {
              img {
                max-width: 220px;
                width: 100%;
              }
            }

            .sidebar-close-cross {
              display: block;
              position: absolute;
              top: 20px;
              right: 20px;
              font-size: 27px;
            }
          }

          nav {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 81.8px);

            .nav-links {
              flex-direction: column;

              li {
                list-style: none;

                .nav-items {
                  text-decoration: none;
                }

                .personalized {
                  display: none !important;
                }
              }

              .mobile-pc-cart {
                display: none;
              }
            }
          }
        }

        .sidebar-active {
          display: block;
          width: 100%;
          height: 100%;
          background: #ffffff;
          position: fixed;
          top: 0px;
          left: 0px;
          z-index: 1;
          box-shadow: -4px -4px 13px gray;
        }
      }
    }
  }

  .home-page {
    .main-banner-block {
      .inner-container {
        .main-banner-img-block {
          .main-banner-img-content {
            .main-banner-link {
              padding: 8px 30px;
              margin-bottom: 9px;
            }
          }
        }
      }
    }

    .shop-your-product {
      .our-container {
        .inner-container {
          max-width: 100%;

          .shop-product-body {
            .spb-right-block {
              .spb-row {
                .spb-left-block {
                  .spb-left-img-content {
                    max-width: 100%;

                    .h3 {
                      font-size: 24px;
                    }

                    .spb-left-row-link {
                      padding: 12px 30px;
                    }
                  }
                }

                .spb-right-block {
                  img {
                    display: block;
                    max-width: 100%;
                  }

                  .spb-right-img-content {
                    max-width: 100%;

                    .h3 {
                      font-size: 24px;
                    }

                    .spb-right-row-link {
                      padding: 12px 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sub-banner-block {
      .inner-container {
        .main-banner-img-block {
          span {
            height: 600px;
          }
        }
      }
    }

    .customize-your-product {
      .inner-container {
        .image-grid {
          grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        }
      }
    }

    .client-logo {
      .inner-container {
        .client-logo-body {
          .client-logo-row {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .about-page {
    .about-page-detail-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .about-page-detail-body {
            .apd-block {
              .apd-row {
                .apd-block-sub {
                  &:hover {
                    .apd-img-content {
                      .h4 {
                        font-size: 15px;
                      }

                      .h6 {
                        font-size: 18px;
                      }

                      .apd-center-underline {
                        margin: 7px 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .about-page-our-details {
      .our-container {
        .inner-container {
          .about-page-our-details-body {
            .about-page-our-details-my-services {
              .inner-container {
                max-width: 100%;

                .about-page-our-details-my-services-row {
                  flex-direction: column;

                  .about-page-our-details-my-services-icon-block {
                    max-width: 600px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .personalized-page {
    .personalized-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .personalized-page-body {
            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-page {
    .product-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-page-body {
            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-page-row {
              grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
            }
          }
        }
      }
    }
  }

  .b2b-page {
    .b2b-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .b2b-page-body {
            .b2b-page-row {
              grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          .product-single-page-block-row {
            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                // bottom: 35px;
                right: 58px;

                span {
                  font-size: 52px;
                  text-transform: capitalize;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 112px;
                left: 60px;
                bottom: 79px;
                width: 100px;
              }
            }
          }
        }
      }
    }
  }

  .checkout-page {
    .checkout-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .checkout-page-row-block {
            flex-direction: column-reverse;
            gap: 20px;
            margin-top: 30px;

            .checkout-page-row-left-block {
              width: 100%;
            }

            .checkout-page-row-right-block {
              width: 30%;
            }
          }
        }
      }
    }
  }

  .contact-page {
    .contact-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .contact-page-body {
            .contact-page-main-block {
              .contact-page-main-row-block {
                display: flex;
                flex-direction: column-reverse;
                gap: 20px;

                .contact-page-form {
                  max-width: 100%;
                }

                .contact-page-iframe {
                  width: 100%;
                }

                .contact-page-details {
                  flex: 0.7;
                }
              }
            }
          }
        }
      }
    }
  }

  .not-found-page {
    .not-found-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .content-page-details {
            gap: 60px;

            .not-found-content-block {
              flex: 1;

              h1 {
                font-size: 130px;
                margin-bottom: 14px;
              }

              .h3 {
                font-size: 30px;
              }

              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 850px) {
  header {
    border-bottom: 1px solid #e2e2e2;
    .upper-menu-block {
      .inner-container {
        .country-block {
          display: none;
        }

        .logo-block {
          img {
            max-width: 134px;
            width: 100%;
          }
        }

        .mobile-icon-responsive {
          display: flex;
          align-items: center;
          gap: 10px;

          .mobile-personalized {
            display: block;
            .personalized {
              display: block;
              // .animated-text{
              //   font-size: 14px;
              //   padding: 7px 20px;
              // }
              .animated-text {
                font-size: 14px;
                padding: 7px 20px;
                font-weight: bold;
                color: #000000 !important;
                background-color: #d4af37 !important;
                font-family: AdobeCaslonProItalic, georgiaItalic,
                  TimesNewRomanItalic, serif;
                font-style: italic;
                animation: flip 1.4s ease-in-out infinite;

                span {
                  margin-top: 8px;
                }
              }
            }
          }

          .hamburgerMenu {
            display: block;
          }

          .mobile-cart-block {
            display: block;
            color: $secondary-color;

            .mobile-shop-cart {
              display: block;
            }
          }
          .c-value-mobile {
            position: absolute;
            top: -24px;
            right: -4px;
            font-size: 12px;
            color: $white-color;
            background-color: $primary-color;
            padding: 5px;
            height: 22px;
            width: 22px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .main-menu {
          display: none;
          padding: 10px 0px;

          .sidebar-top-navbar {
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-block {
              img {
                max-width: 220px;
                width: 100%;
              }
            }

            .sidebar-close-cross {
              display: block;
              position: absolute;
              top: 20px;
              right: 20px;
              font-size: 27px;
            }
          }

          nav {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 81.8px);

            .nav-links {
              flex-direction: column;

              li {
                list-style: none;

                .nav-items {
                  text-decoration: none;
                }

                .personalized {
                  display: none !important;
                }
              }

              .mobile-pc-cart {
                display: none;
              }
            }
          }
        }

        .sidebar-active {
          display: block;
          width: 100%;
          height: 100%;
          background: #ffffff;
          position: fixed;
          top: 0px;
          left: 0px;
          z-index: 1;
          box-shadow: -4px -4px 13px gray;
          // display: block;
          // width: 100%;
          // height: 50%;
          // background: #ffffff;
          // position: absolute;
          // top: 0px;
          // left: 0px;
          // z-index: 1;
          // box-shadow: -4px -4px 13px gray;
        }
      }
    }
  }

  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          .product-single-page-block-row {
            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                // bottom: 44px;
                right: 46px;

                span {
                  font-size: 33px;
                  text-transform: capitalize;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 80px;
                left: 48px;
                bottom: 66px;
                width: 65px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 600px) and (max-device-width: 767px) {
  header {
    border-bottom: 1px solid #e2e2e2;

    .upper-menu-block {
      .inner-container {
        .country-block {
          display: none;
        }

        .mobile-icon-responsive {
          display: flex;
          align-items: center;
          gap: 10px;

          .mobile-personalized {
            display: block;
            .personalized {
              display: block;
              // .animated-text{
              //   font-size: 14px;
              //   padding: 7px 20px;
              // }
              .animated-text {
                font-size: 14px;
                padding: 7px 20px;
                font-weight: bold;
                color: #000000 !important;
                background-color: #d4af37 !important;
                font-family: AdobeCaslonProItalic, georgiaItalic,
                  TimesNewRomanItalic, serif;
                font-style: italic;
                animation: flip 1.4s ease-in-out infinite;

                span {
                  margin-top: 8px;
                }
              }
            }
          }

          .hamburgerMenu {
            display: block;
          }

          .mobile-cart-block {
            display: block;
            color: $secondary-color;

            .mobile-shop-cart {
              display: block;
            }
          }
          .c-value-mobile {
            position: absolute;
            top: -24px;
            right: -4px;
            font-size: 12px;
            color: $white-color;
            background-color: $primary-color;
            padding: 5px;
            height: 22px;
            width: 22px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .main-menu {
          display: none;
          padding: 10px 0px;

          .sidebar-top-navbar {
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-block {
              img {
                max-width: 220px;
                width: 100%;
              }
            }

            .sidebar-close-cross {
              display: block;
              position: absolute;
              top: 20px;
              right: 20px;
              font-size: 27px;
            }
          }

          nav {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 81.8px);

            .nav-links {
              flex-direction: column;

              li {
                list-style: none;

                .nav-items {
                  text-decoration: none;
                }

                .personalized {
                  display: none !important;
                }
              }

              .mobile-pc-cart {
                display: none;
              }
            }
          }
        }

        .sidebar-active {
          display: block;
          width: 100%;
          height: 100%;
          background: #ffffff;
          position: fixed;
          top: 0px;
          left: 0px;
          z-index: 1;
          box-shadow: -4px -4px 13px gray;
          // display: block;
          // width: 100%;
          // height: 50%;
          // background: #ffffff;
          // position: absolute;
          // top: 0px;
          // left: 0px;
          // z-index: 1;
          // box-shadow: -4px -4px 13px gray;
        }
      }
    }
  }

  footer {
    .inner-container {
      max-width: 100%;

      .main-footer {
        .footer-links {
          padding: 0px;
        }
      }
    }
  }

  .home-page {
    .main-banner-block {
      .inner-container {
        .main-banner-img-block {
          .main-banner-img-content {
            .main-banner-link {
              padding: 8px 30px;
              margin-bottom: 25px;
            }
          }
        }
      }
    }

    .shop-your-product {
      .our-container {
        .inner-container {
          max-width: 100%;

          .shop-product-body {
            .spb-right-block {
              .spb-row {
                .spb-left-block {
                  .spb-left-img-content {
                    max-width: 100%;

                    .h3 {
                      font-size: 19px;
                    }

                    .spb-left-row-link {
                      padding: 9px 27px;
                    }
                  }
                }

                .spb-right-block {
                  img {
                    display: block;
                    max-width: 100%;
                  }

                  .spb-right-img-content {
                    max-width: 100%;

                    .h3 {
                      font-size: 19px;
                    }

                    .spb-right-row-link {
                      padding: 9px 27px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sub-banner-block {
      .inner-container {
        .main-banner-img-block {
          span {
            height: 550px;
          }

          .main-banner-img-content {
            max-width: 100%;
            padding: 0px 40px;

            .h3 {
              font-size: 35px;
              margin-bottom: 4px;
            }

            p {
              font-size: 16px;
            }

            .main-banner-link {
              padding: 12px 30px;
            }
          }
        }
      }
    }

    .customize-your-product {
      .inner-container {
        .image-grid {
          grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
        }
      }
    }

    .client-logo {
      .inner-container {
        .client-logo-body {
          .client-logo-row {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .about-page {
    .about-page-detail-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .about-page-detail-body {
            .apd-block {
              .apd-row {
                flex-direction: column;

                .apd-block-sub {
                  &:hover {
                    .apd-img-content {
                      .h4 {
                        font-size: 24px;
                      }

                      .h6 {
                        font-size: 65px;
                      }

                      .apd-center-underline {
                        margin: 15px 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .about-page-our-details {
      .our-container {
        .inner-container {
          .about-page-our-details-body {
            .about-page-our-details-enquiry-block {
              .inner-container {
                .about-page-our-details-enquiry-img-block {
                  .about-page-our-details-enquiry-img-content {
                    .about-page-our-details-enquiry-link {
                      padding: 12px 30px;
                    }
                  }
                }
              }
            }

            .about-page-our-details-my-services {
              .inner-container {
                max-width: 100%;

                .about-page-our-details-my-services-row {
                  flex-direction: column;

                  .about-page-our-details-my-services-icon-block {
                    max-width: 600px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .personalized-page {
    .personalized-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .personalized-page-body {
            flex-direction: column;

            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-page {
    .product-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-page-body {
            flex-direction: column;

            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            flex-direction: column;

            .gallery-image-page-row {
              grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
            }
          }
        }
      }
    }
  }

  .b2b-page {
    .b2b-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .b2b-page-body {
            flex-direction: column;

            .b2b-page-row {
              grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-single-page-block-row {
            gap: 40px;

            .main-row-for-product-single-page {
              flex-direction: column-reverse;
            }

            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                // bottom: 87px;
                right: 142px;

                span {
                  font-size: 70px;
                  text-transform: capitalize;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 176px;
                left: 107px;
                width: 147px;
              }
            }
          }
        }
      }
    }
  }

  .cart-page {
    .our-container {
      .inner-container {
        max-width: 100%;

        .cart-page-block {
          .cart-page-body {
            .cart-sub-total {
              flex-direction: column;
              align-items: end;
              gap: 10px;

              .h4 {
                align-self: start;
              }

              .cart-footer-btn-group {
                max-width: 320px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .checkout-page {
    .checkout-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .checkout-page-row-block {
            flex-direction: column-reverse;
            gap: 20px;
            margin-top: 30px;

            .checkout-page-row-left-block {
              width: 100%;
            }

            .checkout-page-row-right-block {
              width: 40%;
            }
          }
        }
      }
    }
  }

  .contact-page {
    .contact-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .contact-page-body {
            .contact-page-main-block {
              .contact-page-main-row-block {
                display: flex;
                flex-direction: column-reverse;
                gap: 20px;

                .contact-page-form {
                  max-width: 100%;
                }

                .contact-page-iframe {
                  width: 100%;
                }

                .contact-page-details {
                  flex: 0.5;
                }
              }
            }
          }
        }
      }
    }
  }

  .not-found-page {
    .not-found-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .content-page-details {
            gap: 30px;

            .not-found-content-block {
              flex: 1;

              h1 {
                font-size: 100px;
                margin-bottom: 14px;
              }

              .h3 {
                font-size: 23px;
              }

              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-filter-section {
              max-width: 100%;
              width: 100%;
              padding: 13px;
              border: 1px solid;
              position: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              flex-wrap: wrap;

              button{
                max-width: 160px;
                padding: 7px 10px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: 600px) {
  header {
    height: 96px;
    border-bottom: 1px solid #e2e2e2;

    .upper-menu-block {
      .inner-container {
        .social-icons {
          display: none;
        }

        .logo-block {
          img {
            max-width: 124px;
            width: 100%;
          }
        }

        .mobile-icon-responsive {
          display: flex;
          align-items: center;
          gap: 10px;

          .mobile-personalized {
            display: block;
            .personalized {
              display: block;
              // .animated-text{
              //   font-size: 12px;
              //   padding: 5px 7px;
              // }
              .animated-text {
                font-size: 12px;
                padding: 5px 7px;
                font-weight: bold;
                color: #000000 !important;
                background-color: #d4af37 !important;
                font-family: AdobeCaslonProItalic, georgiaItalic,
                  TimesNewRomanItalic, serif;
                font-style: italic;
                animation: flip 1.4s ease-in-out infinite;

                span {
                  margin-top: 6px;
                }
              }
            }
          }

          .hamburgerMenu {
            display: block;
          }

          .mobile-cart-block {
            display: block;
            color: $secondary-color;

            .mobile-shop-cart {
              display: block;
            }
          }

          .c-value-mobile {
            position: absolute;
            top: -24px;
            right: -4px;
            font-size: 12px;
            color: $white-color;
            background-color: $primary-color;
            padding: 5px;
            height: 22px;
            width: 22px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .main-menu {
          display: none;
          padding: 10px 0px;

          .sidebar-top-navbar {
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-block {
              img {
                max-width: 180px;
                width: 100%;
              }
            }

            .sidebar-close-cross {
              display: block;
              position: absolute;
              top: 20px;
              right: 20px;
              font-size: 27px;
            }
          }

          nav {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 131.8px);

            .nav-links {
              flex-direction: column;
              gap: 50px;

              li {
                list-style: none;

                .nav-items {
                  text-decoration: none;
                }

                .personalized {
                  display: none !important;
                }
              }

              .mobile-pc-cart {
                display: none;
              }
            }
          }
        }

        .sidebar-active {
          display: block;
          width: 100%;
          height: 100%;
          background: #ffffff;
          position: fixed;
          top: 0px;
          left: 0px;
          z-index: 1;
          box-shadow: -4px -4px 13px gray;
          // display: block;
          // width: 100%;
          // height: 50%;
          // background: #ffffff;
          // position: absolute;
          // top: 0px;
          // left: 0px;
          // z-index: 1;
          // box-shadow: -4px -4px 13px gray;
        }
      }
    }
  }

  footer {
    border-top: 1px solid #e2e2e2;

    .inner-container {
      max-width: 1400px;
      width: 100%;

      .footer-top {
        .footer-contact {
          flex: 1;
          max-width: 700px;
          width: 100%;
        }
      }

      .main-footer {
        .footer-links {
          padding: 0px 20px;
          flex-direction: column;

          p {
            border: none;
          }
        }
      }
    }
  }

  .home-page {
    .main-banner-block {
      .inner-container {
        padding: 0px !important;

        .main-banner-img-block {
          img {
            display: block;
            max-width: 100%;
          }

          .main-banner-img-content {
            max-width: 100%;

            h1 {
              font-size: 24px;
            }

            .main-banner-link {
              padding: 12px 30px;
            }
          }
        }
      }
    }

    .shop-your-product {
      .our-container {
        .inner-container {
          max-width: 100%;

          .shop-product-body {
            flex-direction: column;
            gap: 20px;

            .spb-left-block-main-img {
              padding-right: 12px;
            }

            .spb-right-block {
              .spb-row {
                gap: 20px;
                flex-direction: column;

                .spb-left-block {
                  .spb-left-img-content {
                    max-width: 100%;
                    align-items: center;
                    padding: 0px 20px;

                    .h3 {
                      font-size: 21px;
                    }

                    .spb-left-row-link {
                      padding: 12px 30px;
                    }
                  }
                }

                .spb-right-block {
                  img {
                    display: block;
                    max-width: 100%;
                  }

                  .spb-right-img-content {
                    max-width: 100%;
                    align-items: center;
                    padding: 0px 20px;

                    .h3 {
                      font-size: 21px;
                    }

                    .spb-right-row-link {
                      padding: 12px 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sub-banner-block {
      .inner-container {
        padding: 0px !important;

        .main-banner-img-block {
          span {
            height: 550px;
          }

          .main-banner-img-content {
            max-width: 100%;
            padding: 0px 20px;

            .h3 {
              font-size: 35px;
              margin-bottom: 4px;
            }

            p {
              font-size: 16px;
            }

            .main-banner-link {
              padding: 12px 30px;
            }
          }
        }
      }
    }

    .customize-your-product {
      .inner-container {
        .image-grid {
          grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
        }
      }
    }

    .client-logo {
      .inner-container {
        .client-logo-body {
          .client-logo-row {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .about-page {
    .about-page-detail-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .about-page-detail-body {
            .apd-block {
              .apd-row {
                flex-direction: column;

                .apd-block-sub {
                  &:hover {
                    .apd-img-content {
                      .h4 {
                        font-size: 22px;
                      }

                      .h6 {
                        font-size: 48px;
                      }

                      .apd-center-underline {
                        margin: 10px 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .about-page-our-details {
      .our-container {
        .inner-container {
          padding: 0px !important;

          .about-page-our-details-body {
            .about-page-our-details-enquiry-block {
              .inner-container {
                margin: 60px 0px;
                padding: 0px !important;

                .about-page-our-details-enquiry-img-block {
                  span {
                    height: 550px;
                  }

                  .about-page-our-details-enquiry-img-content {
                    padding: 0px 20px;
                    .about-page-our-details-enquiry-link {
                      padding: 12px 30px;
                    }
                  }
                }
              }
            }

            .about-page-our-details-my-services {
              .inner-container {
                max-width: 100%;
                .about-page-our-details-my-services-row {
                  flex-direction: column;

                  .about-page-our-details-my-services-icon-block {
                    max-width: 600px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .personalized-page {
    .personalized-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .personalized-page-body {
            flex-direction: column;

            .filter-section {
            }

            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-page {
    .product-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-page-body {
            flex-direction: column;

            .filter-section {
            }

            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            flex-direction: column;

            .gallery-image-page-row {
              grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
            }
          }
        }
      }
    }
  }

  .b2b-page {
    .b2b-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .b2b-page-body {
            flex-direction: column;

            .b2b-page-row {
              grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));

              .quantity-error {
                align-items: start !important;
                flex-direction: column !important;
              }
            }
          }
        }
      }
    }
  }

  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-single-page-block-row {
            gap: 40px;

            .product-single-page-img-group {
              img {
                width: 100%;
                max-width: 85px !important;
                padding: 6px;
              }
            }

            .main-row-for-product-single-page {
              flex-direction: column-reverse;
            }

            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                // bottom: 58px;
                right: 95px;

                span {
                  font-size: 50px;
                  text-transform: capitalize;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 176px;
                left: 107px;
                width: 147px;
              }
            }
          }
        }
      }
    }
  }
  .form-select:focus {
    box-shadow: none;
  }

  .cart-page {
    .our-container {
      .inner-container {
        max-width: 100%;

        .cart-page-block {
          .cart-page-body {
            .cart-sub-total {
              flex-direction: column;
              align-items: end;
              gap: 11px;

              .h4 {
                align-self: start;
              }

              .cart-footer-btn-group {
                max-width: 320px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .checkout-page {
    .checkout-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .checkout-page-row-block {
            flex-direction: column-reverse;
            gap: 20px;
            margin-top: 30px;

            .checkout-page-row-left-block {
              width: 100%;

              .checkout-page-body {
                form {
                  .col-4 {
                    max-width: 100%;
                    width: 100%;
                  }
                }
              }
            }
            .checkout-page-row-right-block {
              width: 50%;
            }
          }
        }
      }
    }
  }

  .contact-page {
    .contact-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .contact-page-body {
            .contact-page-main-block {
              .contact-page-main-row-block {
                display: flex;
                flex-direction: column-reverse;
                gap: 20px;

                .contact-sub-main-block {
                  flex-direction: column;
                }
                .contact-page-form {
                  max-width: 100%;

                  form {
                    padding-bottom: 0px !important;
                  }
                }

                .contact-page-iframe {
                  width: 100%;
                }

                .contact-page-details {
                  flex: 0.5;
                }
              }
            }
          }
        }
      }
    }
  }

  .not-found-page {
    .not-found-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .content-page-details {
            flex-direction: column;
            gap: 50px;

            .not-found-content-block {
              flex: 1;
              width: 100%;

              h1 {
                font-size: 100px;
                margin-bottom: 14px;
              }

              .h3 {
                font-size: 23px;
              }

              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-filter-section {
              max-width: 100%;
              width: 100%;
              padding: 13px;
              border: 1px solid;
              position: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              flex-wrap: wrap;

              button{
                max-width: 160px;
                padding: 7px 10px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .inner-container {
    padding: 0px 20px !important;
  }
}

@media screen and (max-device-width: 480px) {
  header {
    border-bottom: 1px solid #e2e2e2;
  }

  .home-page {
    .main-banner-block {
      .inner-container {
        padding: 0px !important;

        .main-banner-img-block {
          img {
            display: block;
            max-width: 100%;
          }

          .main-banner-img-content {
            max-width: 100%;
            .main-banner-link {
              font-size: 12px;
              padding: 8px 30px;
              margin-bottom: 7px;
            }
          }
        }
      }
    }

    .shop-your-product {
      .our-container {
        .inner-container {
          max-width: 100%;

          .shop-product-body {
            flex-direction: column;
            gap: 20px;

            .spb-left-block-main-img {
              padding-right: 12px;
            }

            .spb-right-block {
              .spb-row {
                gap: 20px;
                flex-direction: column;

                .spb-left-block {
                  .spb-left-img-content {
                    max-width: 100%;

                    .h3 {
                      font-size: 21px;
                    }

                    p {
                      font-size: 14px;
                    }

                    .spb-left-row-link {
                      padding: 12px 30px;
                    }
                  }
                }

                .spb-right-block {
                  img {
                    display: block;
                    max-width: 100%;
                  }

                  .spb-right-img-content {
                    max-width: 100%;

                    .h3 {
                      font-size: 21px;
                    }

                    p {
                      font-size: 14px;
                    }

                    .spb-right-row-link {
                      padding: 12px 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sub-banner-block {
      .inner-container {
        padding: 0px !important;

        .main-banner-img-block {
          span {
            height: 500px;
          }

          .main-banner-img-content {
            max-width: 100%;
            padding: 0px 20px;

            .h3 {
              font-size: 35px;
              margin-bottom: 4px;
            }

            p {
              font-size: 14px;
            }

            .main-banner-link {
              padding: 12px 30px;
            }
          }
        }
      }
    }

    .customize-your-product {
      .inner-container {
        .image-grid {
          grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
          gap: 10px;
        }
      }
    }

    .client-logo {
      .inner-container {
        .client-logo-body {
          .client-logo-row {
            flex-wrap: wrap;
            .client-logo-block {
              img {
                max-width: 80px;
              }
            }
          }
        }
      }
    }
  }

  .about-page {
    .about-page-detail-block {
      .our-container {
        .inner-container {
          .about-page-detail-body {
            .apd-block {
              .apd-row {
                .apd-block-sub {
                  &:hover {
                    .apd-img-content {
                      p {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .about-page-our-details {
      .our-container {
        .inner-container {
          .about-page-our-details-body {
            .about-page-our-details-para {
              p {
                font-size: 14px;
              }
            }

            .about-page-our-details-enquiry-block {
              .inner-container {
                .about-page-our-details-enquiry-img-block {
                  span {
                    height: 500px;
                  }

                  .about-page-our-details-enquiry-img-content {
                    .h4 {
                      font-size: 19px;
                    }

                    .h3 {
                      font-size: 45px;
                    }

                    p {
                      font-size: 14px;
                      margin-bottom: 24px;
                    }

                    .about-page-our-details-enquiry-link {
                      padding: 12px 30px;
                    }
                  }
                }
              }
            }

            .about-page-our-details-my-services {
              .inner-container {
                max-width: 100%;

                .about-page-our-details-my-services-row {
                  flex-direction: column;

                  .about-page-our-details-my-services-icon-block {
                    max-width: 600px;
                    p {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-single-page-block-row {
            gap: 40px;

            .product-single-page-img-group {
              img {
                width: 100%;
                max-width: 50px !important;
                padding: 6px !important;
              }
            }

            .main-row-for-product-single-page {
              flex-direction: column-reverse;
            }

            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                // bottom: 35px;
                right: 64px;

                span {
                  font-size: 54px;
                  text-transform: capitalize;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 118px;
                left: 64px;
                bottom: 80px;
                width: 120px;
              }
            }
          }
        }
      }
    }
  }

  .b2b-page {
    .b2b-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .b2b-page-body {
            .b2b-page-row {
              .b2b-modal-btn {
                .b2b-row-block {
                  .b2b-raw-block-details {
                    .b2b-raw-block-details-price {
                      align-items: start;
                      flex-direction: column;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cart-page {
    .our-container {
      .inner-container {
        max-width: 100%;

        .cart-page-block {
          .cart-page-body {
            ul {
              li {
                .cart-detail-list-item {
                  .right-side-cart-content {
                    .right-cart-heading {
                      flex-direction: column;
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .checkout-page {
    .checkout-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .checkout-page-row-block {
            flex-direction: column-reverse;
            gap: 20px;
            margin-top: 30px;

            .checkout-page-row-left-block {
              width: 100%;
            }

            .checkout-page-row-right-block {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-filter-section {
              max-width: 100%;
              width: 100%;
              padding: 13px;
              border: 1px solid;
              position: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              flex-wrap: wrap;

              button{
                max-width: 160px;
                padding: 7px 10px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  p {
    font-size: 14px !important;
  }

  .padding-mobile-20 {
    padding: 0px 20px !important;
  }
}

@media screen and (max-device-width: 390px) {
  footer {
    .inner-container {
      .fn-link {
        flex-direction: column;
        gap: 12px !important;
      }
    }
  }

  .home-page {
    .main-banner-block {
      .inner-container {
        padding: 0px !important;

        .main-banner-img-block {
          img {
            display: block;
            max-width: 100%;
          }

          .main-banner-img-content {
            max-width: 100%;

            .main-banner-link {
              font-size: 12px;
              padding: 5px 20px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .shop-your-product {
      .our-container {
        .inner-container {
          .shop-product-body {
            .spb-right-block {
              .spb-row {
                .spb-left-block {
                  .spb-left-img-content {
                    .spb-left-row-link {
                      padding: 10px 30px;
                    }
                  }
                }

                .spb-right-block {
                  .spb-right-img-content {
                    .spb-right-row-link {
                      padding: 10px 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sub-banner-block {
      .inner-container {
        padding: 0px !important;

        .main-banner-img-block {
          .main-banner-img-content {
            max-width: 100%;
            padding: 0px 20px;

            .h3 {
              font-size: 35px;
              margin-bottom: 4px;
            }

            p {
              font-size: 14px;
            }

            .main-banner-link {
              padding: 10px 30px;
            }
          }
        }
      }
    }
  }

  .about-page {
    .about-page-detail-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .about-page-detail-body {
            .apd-block {
              .apd-row {
                .apd-block-sub {
                  &:hover {
                    .apd-img-content {
                      .h4 {
                        font-size: 15px;
                      }

                      .h6 {
                        font-size: 18px;
                      }

                      .apd-center-underline {
                        margin: 7px 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .about-page-our-details {
      .our-container {
        .inner-container {
          .about-page-our-details-body {
            .about-page-our-details-enquiry-block {
              .inner-container {
                .about-page-our-details-enquiry-img-block {
                  .about-page-our-details-enquiry-img-content {
                    .h4 {
                      font-size: 19px;
                    }

                    .about-page-our-details-enquiry-link {
                      padding: 10px 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .personalized-page {
    .personalized-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .personalized-page-body {
            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-page {
    .product-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-page-body {
            .product-page-row {
              grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

              // .product-row-block {
              //   .product-raw-block-details {
              //     .h5 {
              //       font-size: 12px;
              //     }
              //   }
              // }
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-page-row {
              grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
            }
          }
        }
      }
    }
  }

  .b2b-page {
    .b2b-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .b2b-page-body {
            .b2b-page-row {
              grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
            }
          }
        }
      }
    }
  }

  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .product-single-page-block-row {
            gap: 40px;

            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                // bottom: 32px;
                right: 56px;

                span {
                  font-size: 46px;
                  text-transform: capitalize;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 112px;
                bottom: 72px;
                left: 58px;
                width: 100px;
              }
            }
          }
        }
      }
    }
  }

  .cart-page {
    .our-container {
      .inner-container {
        .cart-page-block {
          .cart-page-body {
            ul {
              li {
                .cart-detail-list-item {
                  .right-side-cart-content {
                    .right-cart-heading {
                      .cart-detail-list-item-link {
                        .h3 {
                          font-size: 16px;
                        }
                      }

                      .h5 {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .cart-modal {
      .modal-dialog {
        .modal-content {
          .modal-header {
            .btn-close {
              &:focus {
                box-shadow: none;
              }
            }
          }

          .modal-body {
            .form-control,
            .form-select {
              font-size: 14px;
            }

            .form-fixed-height {
              height: 45px;
            }

            .form-control:focus,
            .form-select:focus,
            .accordion-button:focus {
              border-color: #dee2e6;
              outline: 0;
              box-shadow: none;
            }
          }

          .modal-footer {
            .cart-modal-cancel-btn {
              border: 0px;
              outline: none;
              max-width: 100px;
              width: 100%;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border-radius: 11px;
              padding: 10px 20px;
              text-decoration: none;
              text-align: center;
              line-height: 25px;
              background-color: $para-color;
              color: $white-color;
              transition: $transition-effect;

              &:hover {
                background-color: $hover-bg-color;
              }
            }

            .cart-modal-btn {
              border: 0px;
              outline: none;
              max-width: 150px;
              width: 100%;
              height: 45px;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              border-radius: 11px;
              padding: 10px 20px;
              text-decoration: none;
              text-align: center;
              line-height: 25px;
              background-color: $secondary-color;
              color: $white-color;
              transition: $transition-effect;

              &:hover {
                background-color: $hover-bg-color;
              }
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-filter-section {
              max-width: 100%;
              width: 100%;
              padding: 13px;
              border: 1px solid;
              position: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              flex-wrap: wrap;

              button{
                max-width: 100%;
                padding: 7px 30px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: 330px) {
  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          .product-single-page-block-row {
            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                bottom: 30px;
                right: 50px;

                span {
                  font-size: 46px;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 100px;
                left: 50px;
                bottom: 62px;
                width: 70px;
              }
            }
          }
        }
      }
    }
  }

  .cart-page {
    .our-container {
      .inner-container {
        .cart-page-block {
          .cart-page-body {
            ul {
              li .cart-detail-list-item {
                flex-direction: column;

                .right-side-cart-content {
                  margin: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-filter-section {
              max-width: 100%;
              width: 100%;
              padding: 13px;
              border: 1px solid;
              position: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              flex-wrap: wrap;

              button{
                max-width: 100%;
                padding: 7px 30px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: 280px) {
  .product-single-page {
    .product-single-page-block {
      .our-container {
        .inner-container {
          .product-single-page-block-row {
            .product-single-page-main-img {
              .product-single-page-main-image-current-details {
                position: absolute;
                bottom: 38px;
                right: 60px;

                span {
                  font-size: 24px;
                }
              }

              .zodiacs-flower-upper-image {
                position: absolute;
                // top: 100px;
                left: 57px;
                bottom: 52px;
                width: 50px;
              }
            }

            .product-single-page-img-group {
              img {
                width: 100%;
                max-width: 40px !important;
                padding: 6px !important;
              }
            }
          }
        }
      }
    }
  }

  .gallery-page {
    .gallery-image-page-block {
      .our-container {
        .inner-container {
          max-width: 100%;

          .gallery-image-page-body {
            .gallery-image-filter-section {
              max-width: 100%;
              width: 100%;
              padding: 13px;
              border: 1px solid;
              position: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              flex-wrap: wrap;

              button{
                max-width: 100%;
                padding: 7px 30px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
